'use strict';
if (window && !window.$) { // this check is for SG, in SG window.$ is already exist and throw error on reinitialize
    window.jQuery = window.$ = require('jquery');
}

var $prCategorySnippets = $('.pr-category-snippet');
var $prWarForm = $('.pr-war-form');
var $prPdpComponents = $('.pr-pdp-components');

/* eslint-disable no-undef */

/**
 * @function prLoadCategorySnippets
 */
function prLoadCategorySnippets() {
    if (window.POWERREVIEW) {
        window.pwr = window.pwr || function () {
            (pwr.q = pwr.q || []).push(arguments);
        };
        var prCategorySnippets = [];
        if ($prCategorySnippets.length > 0) {
            $($prCategorySnippets).each(function (index) {
                if ($(this).data('pr-plp-component')) {
                    prCategorySnippets.push($(this).data('pr-plp-component'));
                }
            });
        }
        if (prCategorySnippets.length > 0) {
            pwr('render', prCategorySnippets);
        }
    }
}

/**
 * @function prLoadWarForm
 */
function prLoadWarForm() {
    if (window.POWERREVIEW) {
        window.pwr = window.pwr || function () {
            (pwr.q = pwr.q || []).push(arguments);
        };
        if ($prWarForm.length > 0 && $prWarForm.data('pr-war-form')) {
            var prWarFormData = $prWarForm.data('pr-war-form');
            // add on_submit callback method
            prWarFormData.on_submit = function () { // eslint-disable-line
                window.scrollTo(0, 0);
            };
            pwr('render', prWarFormData);
        }
    }
}
// Begin SPL Customization
/**
 * @param {string} viewport - viewport to check against
 * @returns {boolean} true if viewport is current viewport
 */
function prIsViewport(viewport) {
    const currentViewport = window.getComputedStyle(document.querySelector('body'), '::after').getPropertyValue('content').replace(/"/g, '');
    return viewport.indexOf(currentViewport) > -1;
}

function prScrollPdpReviewTab() {
    const $reviewsTab = $('.js-product-tab--reviews');
    const $reviewsTabContent = $('#product-reviews-tab');
    console.log('$reviewsTab ' + $reviewsTab.length);

    if (prIsViewport('desktop')) {
        if ($reviewsTab.length) {
            let $reviewTabButton = $reviewsTab.find('a.js-no-scroll');
            if ($reviewTabButton.length && $reviewTabButton.hasClass('is-active-tab')) {
                $("html, body").animate({ scrollTop: $reviewsTab.offset().top - 50}, 500);
            } else {
                $reviewTabButton.trigger('click');
                $("html, body").animate({ scrollTop: $reviewsTab.offset().top - 50 }, 500);
            }
        }
    } else if ($reviewsTabContent.length) {
        const $reviewsAccordionTarget = $reviewsTabContent.find('.js-accordion__target');

        if ($reviewsAccordionTarget.length && !$reviewsTabContent.hasClass('is-expanded')) {
            $reviewsAccordionTarget.trigger('click');
        }

        $("html, body").animate({ scrollTop: $reviewsTabContent.offset().top }, 500);
    }
}

function prReplaceScrollAnchors() {
    // replace review-count anchors with spans to prevent conflicting events triggering from powerreviews handlers
    $('a.pr-snippet-review-count').each(function () {
        let prScrollAnchor = this;
        let prScrollSpan = document.createElement("span");
        prScrollSpan.innerHTML = prScrollAnchor.innerHTML;
        prScrollSpan.className = prScrollAnchor.className;
        prScrollSpan.id = prScrollAnchor.id;
        prScrollAnchor.parentNode.replaceChild(prScrollSpan, prScrollAnchor);
    });
}

// End SPL Customization

/**
 * @function prLoadPdpComponents
 * @param {Object} powerReviewsData --powerReviewsData from product variant
 */
function prLoadPdpComponents(powerReviewsData) {
    if (window.POWERREVIEW) {
        window.pwr = window.pwr || function () {
            (pwr.q = pwr.q || []).push(arguments);
        };
        if ($prPdpComponents.length > 0) {
            var prPdpData = powerReviewsData || $prPdpComponents.data('pr-pdp-components');
            // pwr('render', prPdpData);
            // Begin SPL Customization
            // Render a copy of the PDP component as the mobile version
            var prPDPDataArray = [];
            try {
                let parsedPrPdpData = typeof prPdpData === 'string' ? JSON.parse(prPdpData) : prPdpData;
                prPDPDataArray.push(parsedPrPdpData);
                let prPdpDataMobile = JSON.parse(JSON.stringify(parsedPrPdpData));
                prPdpDataMobile.components = {ReviewSnippet: 'pr-reviewsnippet-mobile'};
                prPDPDataArray.push(prPdpDataMobile);
                pwr('render', prPDPDataArray);
            } catch (e) {
                console.log(e);
            }
            // End SPL Customization
        }
        // Begin SPL Customization
        $('body').off('click', '.pr-snippet-review-count, .pr-snippet-stars').on('click', '.pr-snippet-review-count, .pr-snippet-stars', function (e) {
            e.preventDefault();
            e.stopPropagation();
            prScrollPdpReviewTab();
            prReplaceScrollAnchors();
            return false;
        });

        if (window.location.hash === '#product-reviews-tab') {
            // remove hash so that the scroll event will not re-run if script is reloaded by ajax
            window.history.pushState({}, null, window.location.href.replace(location.hash,""));

            // disabled scrolling to reviews tab on page load because other content may load after the scroll finishes, and push the reviews tab out of view anyways.
            // it is possible to re-enable this but with a timeout delay to allow other content to load first, but this is not ideal because it may result in a noticeable pause
            // before scroll if we wait long enough for the other content to load.
            // prScrollPdpReviewTab();
        }
        setTimeout(function () {
            // replace review-count anchors with spans to prevent conflicting events triggering from powerreviews handlers.
            // we do not control the timing of when powerreviews renders the review count anchors, so we need to wait a bit before replacing them.
            // if the timing is off, the anchors will not be replaced and clicking it will trigger jittery scrolling if the review tab is already open.
            prReplaceScrollAnchors();
        }, 1000);
        // End SPL Customization
    }
}

$(document).ready(function () {
    $(document).ajaxComplete(function (event, xhr, settings) {
        if (settings.url.includes('Search-UpdateGrid') || $('#search-result-items').length > 0 || $('#product-search-results').length > 0 || $('#content-search-results-pane').length > 0) {
            /* need to get new number of product
            divs if added new (#search-result-items is for SG) */
            $prCategorySnippets = $('.pr-category-snippet');
            prLoadCategorySnippets();
        }
        if ($('#product-content').length > 0) { // this is for SG PDP variant change
            $prPdpComponents = $('.pr-pdp-components');
            prLoadPdpComponents();
        }
    });
    if ($prCategorySnippets.length > 0) { // only fire if user is on PLP page
        prLoadCategorySnippets();
        // Begin SPL Customization
        $('body').on('click', '.pr-category-snippet__total, .pr-snippet-stars', function () {
            let $productTile = $(this).closest('.product-tile');
            if ($productTile.length) {
                let $nameLink = $productTile.find('.name-link');
                window.location = $nameLink.attr('href').concat('#product-reviews-tab');
            }
        });
        // End SPL Customization
    }
    if ($prWarForm.length > 0) { // only fire if user is on write review page
        prLoadWarForm();
    }
    if ($prPdpComponents.length > 0) { // only fires if user is on PDP page
        prLoadPdpComponents();
    }
    $('body').on('product:updateAvailability', function (e, response) {
        prLoadPdpComponents(response.product.powerReviewsComponents);
    });
});
